import {RootStore} from '../../stores/RootStore';
import {WidgetEventHandler} from './types';
import {getCtaButtonClickHandler} from './getCtaButtonClickHandler';
import {getAppErrorHandler} from './getAppErrorHandler';
import {FedopsInteractions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/fedopsInteractions';

const openNotifyMeModal = async (rootStore: RootStore): Promise<void> => {
  const productId = rootStore.$state.product.id;
  const variantId = rootStore.$state.variantId;
  const errorHandler = getAppErrorHandler(rootStore);

  try {
    await rootStore.wixStoresApi.backInStock.openBackInStockEmailModal({productId, variantId});
  } catch (error) {
    /* istanbul ignore next reason: no need to check  */
    await errorHandler(error);
  }
};

export const handleNotifyMeClick = (rootStore: RootStore): WidgetEventHandler<any> => {
  return getCtaButtonClickHandler(rootStore, () =>
    rootStore.reportFedopsInteraction(FedopsInteractions.NotifyMe, () => openNotifyMeModal(rootStore)),
  );
};
