import {RootStore} from '../../stores/RootStore';
import {FedopsInteractions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/fedopsInteractions';
import {getCtaButtonClickHandler} from './getCtaButtonClickHandler';
import {WidgetEventHandler} from './types';
import {createCheckout} from '../createCheckout';
import {publishInitiateCheckoutTrackEvent} from '../product-service/publishInitiateCheckoutTrackEvent';
import {interruptAction} from './interruptAction';

export const handleBuyNowClick = (rootStore: RootStore): WidgetEventHandler<any> => {
  return getCtaButtonClickHandler(rootStore, () => {
    const action = () =>
      rootStore.reportFedopsInteraction(FedopsInteractions.BuyNow, () =>
        createCheckout(rootStore, {withNavigateToCheckout: true})
          .then((checkout) => publishInitiateCheckoutTrackEvent(rootStore, checkout.checkoutId as string))
          .then(() => Promise.resolve()),
      );

    return interruptAction(rootStore.wixCodeService.onBuyNowInterruption, action);
  });
};
