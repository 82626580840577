import {capitalizeFirstLetters} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/utils';

export const mapPageToBreadcrumbsItem = <T extends {name: string; url?: string}>(
  page: T,
  transformName: boolean,
): $w.Breadcrumbs.Item => {
  return {
    label: transformName ? capitalizeFirstLetters(page.name) : page.name,
    link: page.url,
  };
};
