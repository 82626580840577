import {RootStore} from './RootStore';
import {fetchProduct} from '../services/product-service/fetchProduct';
import {RootStoreState} from './RootStoreState';
import {getReferringPage} from '@wix/wixstores-client-storefront-sdk/dist/es/src/utils/sitemap/sitemapUtils';

const getInitialData = async (rootStore: RootStore, slug: string) => {
  const [
    product,
    {isCollectingRequests: isCollectingBackInStockRequests},
    contextualProducts,
    storeSettings,
    siteStructure,
    referringPageId,
    referringPagePath,
  ] = await Promise.all([
    fetchProduct(rootStore, slug), // TODO:Zeev: fetchProduct &  fetchBackInStockSettings are both gql calls that can be merged into one call
    rootStore.wixStoresApi.backInStock.fetchBackInStockSettings(),
    rootStore.wixStoresApi.product.getContextualProducts(),
    rootStore.wixStoresApi.settings.getSettings(),
    rootStore.wixCodeApi.site.getSiteStructure({includePageId: true}),
    rootStore.wixStoresApi.product.getReferringPageId(),
    rootStore.wixStoresApi.product.getReferringPagePath(),
  ]);

  if (!product) {
    return null;
  }

  const absoluteProductUrl = await rootStore.wixStoresApi.product.getAbsoluteProductUrl({slug: product.urlPart});
  const referringPage = await getReferringPage(rootStore.wixCodeApi, {
    pageId: referringPageId as string,
    pagePath: referringPagePath,
  });
  return {
    product,
    isCollectingBackInStockRequests,
    contextualProducts,
    storeSettings,
    absoluteProductUrl,
    siteStructure,
    referringPage,
  };
};

export const createInitialState = async (rootStore: RootStore, slug: string): Promise<RootStoreState | undefined> => {
  const {warmupData} = rootStore.controllerParams.controllerConfig.wixCodeApi.window;
  const {flowAPI} = rootStore.controllerParams;

  if (flowAPI.environment.isSSR) {
    warmupData.set(slug, await getInitialData(rootStore, slug));
  }

  const initialDataFromWarmup = warmupData.get(slug);
  const initialData = initialDataFromWarmup || (await getInitialData(rootStore, slug));
  if (initialData) {
    return new RootStoreState(rootStore, initialData);
  }
};
